<script>
import BaseProfileManager from '@/pages/connect/BaseProfileManager'
import { Relation as RelationModel } from '@/models/Connect/Relation'

export default {
  name: 'MediaProfilesManager',

  extends: BaseProfileManager,

  props: {
    mediaProfileId: {
      type: [String, Number],
      default: null
    }
  },

  computed: {
    isNewModel () {
      return !this.mediaProfileId
    },

    sidebarTitle () {
      return 'Media Profile Manager'
    },

    sidebarDescription () {
      return 'Media Profile Description'
    }
  },

  mounted () {
    if (!this.isNewModel) {
      this.getModelData()
    } else {
      const profile = new RelationModel()

      this.form.merge(profile)
    }
  },

  methods: {
    getModelData () {
      this.isLoading = true

      this.$api.get(`/staff/connect/media-profiles/${this.mediaProfileId}`)
        .then((response) => {
          const profile = new RelationModel(response.data.data)

          this.form.merge(profile)
        })
        .catch((error) => {
          this.$displayRequestError(error, this.$t('errors.error'))
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    submitHandler () {
      if (this.isNewModel) {
        return this.form.post('/staff/connect/media-profiles')
      }

      return this.form.patch(`/staff/connect/media-profiles/${this.mediaProfileId}`)
    },

    onSuccessHandler (response) {
      this.$notify.success({
        title: this.$t('general.success'),
        duration: 5000,
        message: this.isNewModel ? 'New media profile added' : 'Updated Media profile'
      })

      if (!this.isNewModel) return

      if (!this.stayOnPageAfterSave) {
        this.$router.push({ name: 'staff.connect.media-profiles.edit', params: { mediaProfileId: response.data.id } })
        this.form.id = response.data.id
      } else {
        this.form.reset()
        this.$v.form.$reset()
      }
    },

    onErrorHandler (error) {
      this.$displayRequestError(error, this.$t('errors.cannot_add_media_profile'))
    }
  }
}
</script>
